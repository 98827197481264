import React, { useEffect, useState} from "react";
import { useMedia } from "use-media";
import loadScript from "../../react/loadscript";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import { RequestForm } from "../../components/form";
import * as R from "ramda";
import emailBlockList from "../../common/emailBlockList";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"
import {CookiesPoup} from "../homepage"

const OgImage = require("../../assets/img/home_page_assist/home_header_assist.png");

const parseDomain = require("parse-domain");

const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getDomain = R.compose(
  R.ifElse(R.startsWith("."), R.slice(1, Infinity), R.identity),
  R.converge(R.unapply(R.join(".")), [
    R.prop("subdomain"),
    R.prop("domain"),
    R.prop("tld")
  ]),
  parseDomain
);

const domainIsExistedInBlackList = domain => {
  return R.findIndex(R.equals(domain), emailBlockList) !== -1;
};

const isBlockedDomain = R.compose(
  domainIsExistedInBlackList,
  getDomain
);

const validEmail = email => EmailRegex.test(email);

export default function Home(props) {
  const [contactId, setContactId] = useState("");
  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    avgTicket: "",
    employees: "",
    agents: "",
    loader: false
  });
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    if (!window._load_script) {
      loadScript("/roi-form.js")
        .then(script => {
          // console.log("load scropt success")
          console.log(script);
          // Grab the script object in case it is ever needed.
          // this.mapScript = script;
          // this.setState({ apiLoaded: true });
        })
        .catch(err => {
          console.log("load scropt error");
          console.error(err.message);
        });
    }
  }, []);

  function calculate() {
    setState({ ...state, loader: true });
    const name = state.name;
    const email = state.email;
    fetch("/calculate-roi", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        company: state.company,
        avgTicket: state.avgTicket,
        employees: state.employees,
        agents: state.agents
      })
    })
      .then(res => res.json())
      .then(res => {
        setState({ ...state, loader: false });
        setContactId(res.contactId);
      })
      .catch(error => {
        setState({ ...state, loader: false });
      });
  }

  function signup() {
    setState({ ...state, loader: true });
    fetch("/roi-signup", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        contactId,
        avgTicket: state.avgTicket
      })
    })
      .then(res => res.json())
      .then(res => {
        setState({
          ...state,
          loader: false,
          name: "",
          email: "",
          agents: "",
          avgTicket: "",
          company: "",
          employees: ""
        });

        setShowThankYou(true);
        setContactId("");
      })
      .catch(error => {
        setState({ ...state, loader: false });
      });
  }
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot ROI Calculator | Workativ Assistant Chatbot"
        description="Lower your helpdesk costs easily using chatbot solutions. Use the chatbot ROI calculator to get an estimate of your cost savings using workativ's chatbot solution."
        keywords={[""]}
        ogTitle="Free Chatbot ROI Calculator | Workativ Assistant Chatbot"
        ogDescription="Lower your helpdesk costs easily using chatbot solutions. Use the chatbot ROI calculator to get an estimate of your cost savings using workativ's chatbot solution."
        ogImage={OgImage}
      />
      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <>
            <div>
              <section className="skit_contactus roi_page_contact">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 roi-form">
                      <h1>ROI Calculator</h1>
                      <h2>
                      Chatbot ROI Calculator
                      </h2>
                      <p style={{color:"#193A5D"}}>
                      Through workflow automation and conversational ai, workativ assistant can help you automate your support requests, thereby helping you cut down on workplace support costs and increase productivity. Use this simple calculator to get an estimate of your cost savings.
                      </p>
                      <div className="roi_ul_wrapper">
                        <p style={{fontWeight:"bold",color:"#000000"}}>You can get an estimate of:  </p>
                        <ul>
                          <li>Agent hours saved</li>
                          <li>Cost savings per month</li>
                        </ul>
                      </div>
                      <p style={{color:"#193A5D"}}>
                      If you find your employee requests for support are exceeding available helpdesk capacity, you need a scalable digital solution that workativ provides.{" "}
                      </p>
                      <p style={{fontWeight:"bold",color:"#000000 !important"}}>Check your savings and try workativ assistant today! </p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 roi-form-right">
                      {state.loader && (
                        <div
                          className="loader_demo"
                          id="loader_demo"
                          style={{ display: "flex" }}
                        >
                          <div className="loader"></div>
                        </div>
                      )}
                      {contactId.length == 0 ? (
                        <>
                          <div className="roi_form_wrapper">
                            <div
                              id="_form_29_"
                              className="_form _form_29 _inline-form  _dark"
                            >
                              <h2>ROI Calculator</h2>
                              <div className="_form-content">
                                <div className="_form_element _x38006328 _full_width ">
                                  <label className="_form-label">
                                    Full Name
                                  </label>
                                  <div className="_field-wrapper">
                                    <input
                                      type="text"
                                      name="fullname"
                                      placeholder="Type your name"
                                      onChange={e =>
                                        setState({
                                          ...state,
                                          name: e.target.value
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="_form_element _x31260594 _full_width ">
                                  <label className="_form-label">
                                    Business Email*
                                  </label>
                                  <div
                                    className={
                                      state.email.length > 0 &&
                                      (!validEmail(state.email) ||
                                        isBlockedDomain(state.email))
                                        ? "_field-wrapper error_msg"
                                        : "_field-wrapper"
                                    }
                                  >
                                    <input
                                      type="text"
                                      name="email"
                                      placeholder="Type your email"
                                      onChange={e =>
                                        setState({
                                          ...state,
                                          email: e.target.value
                                        })
                                      }
                                      required
                                    />
                                    <span>
                                      {!validEmail(state.email) ||
                                      isBlockedDomain(state.email)
                                        ? "Invalid email or not a corporate email"
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="_form_element _field8 _full_width ">
                                  <label className="_form-label">
                                    Company Name
                                  </label>
                                  <div className="_field-wrapper">
                                    <input
                                      type="text"
                                      name="field[8]"
                                      placeholder="Type Company name"
                                      onChange={e =>
                                        setState({
                                          ...state,
                                          company: e.target.value
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="_form_element _field39 _full_width ">
                                  <label className="_form-label">
                                    Average Monthly Tickets
                                  </label>
                                  <div className="_field-wrapper">
                                    <input
                                      type="text"
                                      name="field[39]"
                                      placeholder="Monthly Tickets"
                                      pattern="[0-9]*"
                                      value={state.avgTicket}
                                      onChange={e => {
                                        setState({
                                          ...state,
                                          avgTicket: e.target.validity.valid
                                            ? e.target.value
                                            : state.avgTicket
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="_form_element _field40 _full_width ">
                                  <label className="_form-label">
                                    Number of Agents
                                  </label>
                                  <div className="_field-wrapper">
                                    <input
                                      type="text"
                                      name="field[40]"
                                      placeholder="Agents"
                                      pattern="[0-9]*"
                                      value={state.agents}
                                      onChange={e => {
                                        setState({
                                          ...state,
                                          agents: e.target.validity.valid
                                            ? e.target.value
                                            : state.agents
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="_form_element _field42 _full_width ">
                                  <label className="_form-label">
                                    Number of Employees
                                  </label>
                                  <div className="_field-wrapper">
                                    <input
                                      type="text"
                                      name="field[42]"
                                      placeholder="Employees"
                                      pattern="[0-9]*"
                                      value={state.employees}
                                      onChange={e => {
                                        setState({
                                          ...state,
                                          employees: e.target.validity.valid
                                            ? e.target.value
                                            : state.employees
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <p>
                                  Please note that the calculation is an
                                  estimate of savings. Your actual results may
                                  vary.
                                </p>
                                <div className="_button-wrapper _full_width">
                                  <button
                                    id="_form_29_submit_1"
                                    className={
                                      state.name.length == 0 ||
                                      state.email.length == 0 ||
                                      !validEmail(state.email) ||
                                      isBlockedDomain(state.email) ||
                                      state.company.length == 0 ||
                                      state.avgTicket.length == 0 ||
                                      state.agents.length == 0 ||
                                      state.employees.length == 0
                                        ? "_submit btn-deactivated"
                                        : "_submit"
                                    }
                                    disabled={
                                      state.name.length == 0 ||
                                      state.email.length == 0 ||
                                      !validEmail(state.email) ||
                                      isBlockedDomain(state.email) ||
                                      state.company.length == 0 ||
                                      state.avgTicket.length == 0 ||
                                      state.agents.length == 0 ||
                                      state.employees.length == 0
                                    }
                                    onClick={() => calculate()}
                                  >
                                    Calculate ROI
                                  </button>
                                </div>
                                <div className="_clear-element"></div>
                              </div>
                              <div
                                className="_form-thank-you"
                                style={{ display: "none" }}
                              ></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="roi_form_wrapper roi_form_wrapper_results">
                          <h2>ROI Calculator Results</h2>
                          <div className="roi_form_results">
                            <div className="roi_form_results_left">
                              <h4>
                                $
                                {(state.avgTicket / 2) *
                                  process.env.GATSBY_APP_TICKET_PRICE *
                                  12}{" "}
                                <span>(Approx.)</span>
                              </h4>
                              <p>In savings per year</p>
                            </div>
                            <div className="roi_form_results_right">
                              <h4>
                                {(((state.avgTicket / 2) *
                                  process.env.GATSBY_APP_ROI_HOURS) /
                                  60) *
                                  12}{" "}
                                Hrs <span>(Approx.)</span>
                              </h4>
                              <p>Agent hours saved per year</p>
                            </div>
                          </div>
                          <div className="_button-wrapper _full_width">
                            <button
                              id="_form_29_submit"
                              className="_submit"
                              onClick={signup}
                            >
                              Sign up for 30-days free trial
                            </button>
                          </div>
                        </div>
                      )}

                      {/* modal popup fixed */}
                      {showThankYou && (
                        <ModalThankYou
                          closeModal={() => {
                            setShowThankYou(false);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </section>
              {/*Contact form End*/}
              <RequestForm isFooterForm={true} />
              {/* {isSmall ? null : <OnScrollPopup />} */}

            </div>
          </>
          {/* <GoToPopup />
          <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

function ModalThankYou(props) {
  const { closeModal } = props;
  return (
    <div className="modal_submit_form first_popup_form">
      <div
        className="modal fade first_popup_form_modal show"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "flex" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content modal-content-popup"
            id="beta-detail-page"
          >
            <div className="modal-header">
              <button type="button" className="close">
                <span
                  className="btn_close_popup"
                  aria-hidden="true"
                  onClick={() => closeModal()}
                >
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body-header ">
              <div className="modal-body">
                <div className="main-slider-button beta-button popup_form_pricing">
                  <h5>
                    Thank You for Registering for the Workativ Assistant Trial!
                    We’ll get back to you soon!
                  </h5>
                </div>
              </div>
              <div className="modal_body_right">
                <img loading = "lazy"
                  src="/static/register-dc4b3e9756a6574766da85c73067b2d3.png"
                  alt="register"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
